import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import Container from "@components/layout/container"
import Image from "@components/image"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Grid from "@components/elements/grid"
import Div from "@components/elements/div"
import Flex from "@components/elements/flex"
import theme from "../styles/theme"
import GradientText from "@components/common/gradient-text"
import Button from "@components/elements/button"
import ServiceLogo from "@components/image/service-logo"
import { useTheme } from "styled-components"

const DashboardTemplates = ({ data }) => {
  const dashboards = data.dashboards.edges
  const { color } = useTheme()

  return (
    <Layout
      fullWidth
      title={`Dashboard Templates`}
      description={`Start visualizing your data fast with our editable dashboard templates. They’re chock-full of essential metrics for everyone’s favourite services. Pick a template, sign up, connect your data, and watch your dashboard metrics come to life.`}
    >
      <Container>
        <Flex gap="2rem" alignItems="center" margin="4rem 0 6rem">
          <Heading maxWidth="600px" center>
            Get up and running with a{" "}
            <GradientText variant="homepage">dashboard template</GradientText>
          </Heading>
          <Paragraph center maxWidth="800px">
            {`Start visualizing your data fast with our editable dashboard
            templates. They’re chock-full of essential metrics for everyone’s
            favourite services. Pick a template, sign up, connect your data, and
            watch your dashboard metrics come to life.`}
          </Paragraph>
          <Button.PowerMetrics noticeColor={color.indigo600}>
            Get Started Free
          </Button.PowerMetrics>
        </Flex>
        <Grid
          gap="4rem 2rem"
          columns="repeat(3, 1fr)"
          columnsMd="1fr"
          columnsSm="1fr"
          margin="0 0 6rem"
        >
          {dashboards.map(({ node: dashboard }, i) => (
            <Div key={`dashboard-template-example-${i}`}>
              <Flex
                background="#f3f5fc"
                borderRadius="1rem"
                padding="3rem 2rem"
                margin="0 0 2rem"
                justifyContent="center"
              >
                <Image
                  radius={0.5}
                  src={dashboard.image}
                  objectFit="scale-down"
                  showOverflow
                  loading="lazy"
                  style={{
                    borderRadius: "0.5rem",
                    boxShadow: theme.shadow.dramatic,
                    margin: 0,
                  }}
                />
              </Flex>
              <ServiceLogo service={dashboard.service} />
              <Heading as="h4" margin="0 0 1rem">
                {dashboard.name}
              </Heading>
              <Paragraph lineHeight="1.6" fontSize="1rem" margin="0 0 1.5rem">
                {dashboard.description}
              </Paragraph>
              <Button
                href={dashboard.url}
                arrow
                background="#EDF3FD"
                color="#4B57C5"
                notice={false}
              >
                Get this dashboard
              </Button>
            </Div>
          ))}
        </Grid>
      </Container>
    </Layout>
  )
}

DashboardTemplates.propTypes = {
  data: PropTypes.object.isRequired,
}

export default DashboardTemplates

export const pageQuery = graphql`
  query DashboardTemplatesQuery {
    dashboards: allInstantDashboard {
      edges {
        node {
          id
          name
          description
          url
          image
          service
        }
      }
    }
  }
`
