import React from "react"
import Flex from "@components/elements/flex"
import Image from "@components/image"
import { useServiceLogos } from "@hooks/use-service-logos"
import PropTypes from "prop-types"

const findLogo = ({ logos, name }) => {
  const result = logos.find(({ node }) => {
    return node.key.toLowerCase() === name.toLowerCase()
  })

  const node = result ? result.node : false

  if (node && node.icon) {
    return {
      cdn: node.icon.cdn,
      name: node.name,
    }
  } else {
    return { cdn: "", name: "File does not exist" }
  }
}

const ServiceLogo = ({ service, ...rest }) => {
  const logos = useServiceLogos()
  const logo = findLogo({ logos, name: service })

  return (
    <Flex
      width="48px"
      height="48px"
      padding="10px"
      background="#f3f5fc"
      borderRadius="100%"
      margin="0 0 1rem"
      justifyContent="center"
      {...rest}
    >
      <Image file={logo} />
    </Flex>
  )
}

ServiceLogo.propTypes = {
  service: PropTypes.string,
}

export default ServiceLogo
