import { useStaticQuery, graphql } from "gatsby"

export const useServiceLogos = () => {
  const { services } = useStaticQuery(
    graphql`
      query ServiceLogoQuery {
        services: allServices {
          edges {
            node {
              key
              name
              icon {
                cdn
              }
            }
          }
        }
      }
    `
  )

  return services.edges
}
